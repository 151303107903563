import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../../components/Layout"
import Seo from "../../../../components/seo"
import { BlogList } from "../../../../components/blog-list"
import { Box } from "@mui/material"

const TagPage = ({ data, params }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const tags = data.allMicrocmsTag.edges.map(x => x.node)
  const categories = data.allMicrocmsCategory.edges.map(x => x.node)
  const blogs = data.allMicrocmsBlog
  return (
    <Layout title={siteTitle} tags={tags} categories={categories}>
      <Box sx={{paddingBottom:".5em"}}>タグ"{data.microcmsTag.name}"に関する{blogs.totalCount}件のブログ記事があります</Box>
      <BlogList blogs={data.allMicrocmsBlog.edges}/>
    </Layout>
  )
}

export default TagPage

export const Head = ({ data }) => (
  <Seo
    title={"タグ "+data.microcmsTag.name+" に関連するページ一覧"}
    description={"タグ " + data.microcmsTag.name + " に関連するページのまとめです。" + data.allMicrocmsBlog.totalCount + "件のページがあります。"}
  />
);

export const query = graphql`
  query TagsQuery($tagId: String!) {
    allMicrocmsBlog(filter: {tags: {elemMatch: {id: {eq: $tagId}}}}) {
      totalCount
      edges {
        node {
          blogId
          title
          date
          publishedAt
          thumbnail {
            url
          }
          description
          tags {
            name
          }
          category {
            name
          }          
        }
      }
    }
    microcmsTag(tagId : {eq: $tagId}) {
      tagId
      name
    }
    site {
      siteMetadata {
        title
      }
    }
    allMicrocmsCategory(limit: 30, skip:0) {
      totalCount
      edges {
        node {
          categoryId
          name
          blogsCount
        }
      }
    }
    allMicrocmsTag(limit: 100, skip:0) {
      totalCount
      edges {
        node {
          tagId
          name
          blogsCount
        }
      }
    }
  }
`